import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vClickOutside from 'click-outside-vue3'
import ActionCableVue from 'actioncable-vue'

const actionCableVueOptions = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: 'wss://api.tnpg.test.ut.in.ua/cable',
  connectImmediately: true
}

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(vClickOutside)
  .use(ActionCableVue, actionCableVueOptions)
  .mount('#app')
