import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/RegistrationStepOne.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/registration-step-two',
    name: 'RegistrationStepTwo',
    component: () => import('../views/RegistrationStepTwo.vue')
  },
  {
    path: '/password-forgot',
    name: 'PasswordForgot',
    component: () => import('../views/PasswordForgot.vue')
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () => import('../views/NewPassword.vue')
  },
  {
    path: '/dashboard/home',
    name: 'HomeDashboard',
    component: () => import('../views/dashboard/Home.vue')
  },
  {
    path: '/dashboard/history-of-naughty-meter',
    name: 'HistoryOfNaughtyMeter',
    component: () => import('../views/dashboard/HistoryOfNaughtyMeter.vue')
  },
  {
    path: '/dashboard/history-of-balance',
    name: 'HistoryOfBalance',
    component: () => import('../views/dashboard/HistoryOfBalance.vue')
  },
  {
    path: '/dashboard/meter-management',
    name: 'MeterManagement',
    component: () => import('../views/dashboard/MeterManagement.vue')
  },
  {
    path: '/dashboard/add-gnome',
    name: 'AddGnome',
    component: () => import('../views/dashboard/AddGnome.vue')
  },
  {
    path: '/dashboard/welcome',
    name: 'Welcome',
    component: () => import('../views/dashboard/Welcome.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/registration-step-two', '/password-forgot', '/new-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
