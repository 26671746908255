<template>
  <div class="col-md-12">
    <div class="card card-container">
      <Form
        @submit="handleRegister"
        :validation-schema="schema"
      >
        <div class="form-group">
          <Field
            name="first_name"
            type="text"
            placeholder="First name"
            class="form-control"
          />
          <ErrorMessage
            name="first_name"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message.first_name" />
          </div>
        </div>
        <div class="form-group">
          <Field
            name="last_name"
            type="text"
            placeholder="Last name"
            class="form-control"
          />
          <ErrorMessage
            name="last_name"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message.last_name" />
          </div>
        </div>
        <div class="form-group">
          <Field
            name="email"
            type="email"
            placeholder="Email"
            class="form-control"
          />
          <ErrorMessage
            name="email"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message.email" />
          </div>
        </div>
        <div class="form-group">
          <Field
            name="password"
            type="password"
            placeholder="Password"
            class="form-control"
          />
          <ErrorMessage
            name="password"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message.password" />
          </div>
        </div>
        <div class="form-group">
          <Field
            name="password_confirmation"
            type="password"
            placeholder="Password confirmation"
            class="form-control"
          />
          <ErrorMessage
            name="password_confirmation"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message.password_confirmation" />
          </div>
        </div>
        <div class="form-button-group">
          <div class="form-group">
            <button
              type="submit"
              class="registration__button-next"
            >
              Next
            </button>
          </div>
        </div>
        <router-link
            class="registration__button-login"
            to="/login"
          ><span>Already registered?</span>Log in</router-link>
      </Form>

    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import * as yup from 'yup'

export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock
  },
  data () {
    const schema = yup.object().shape({
      first_name: yup
        .string()
        .required('Username is required')
        .min(3, 'Must be at least 3 characters')
        .max(20, 'Must be maximum 20 characters'),
      last_name: yup
        .string()
        .required('Username is required')
        .min(3, 'Must be at least 3 characters')
        .max(20, 'Must be maximum 20 characters'),
      email: yup
        .string()
        .required('Email is required')
        .email('Email is invalid')
        .max(50, 'Must be maximum 50 characters'),
      password: yup
        .string()
        .required('Password is required')
        .min(6, 'Must be at least 6 characters')
        .max(40, 'Must be maximum 40 characters'),
      password_confirmation: yup
        .string()
        .required('Password confirmation is required')
        .min(6, 'Must be at least 6 characters')
        .max(40, 'Must be maximum 40 characters')
    })

    return {
      schema,
      isModalOpen: false,
      message: null,
      dataPopup:
        'Ссылка для подверждения емейла была отправлена на почту. Пожалуйста, перейдите по ссылке в письме. Если не нашли письма - проверьте папку спам.'
    }
  },
  computed: {
  },
  methods: {
    handleRegister (user) {
      this.$store.dispatch('auth/register', user).then(
        (data) => {
          this.handleLogin(user)
        },
        (error) => {
          this.message = error.response.data.errors
        }
      )
    },
    handleLogin (user) {
      this.$store.dispatch('auth/login', user).then(
        (res) => {
          localStorage.setItem('user', JSON.stringify(res.token))
          this.$router.push('/registration-step-two')
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.registration {
  &__button-login {
    text-align: center;
    color: #7BBBDF;
    font-size: 12px;
    padding-top: 15px;
    display: block;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:active {
      color: #000000;
    }

    span {
      color: #C4C4C4;
      display: inline-block;
      padding-right: 10px;
    }
  }

  &__button-next {
    @include first-button;
    width: 200px;
    height: 40px;
  }
}
.form-control {
  margin-bottom: 10px;
}

.form-group {
  position: relative;
}

.form-button-group {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

@media (min-width: $break-xxlg) {
  .registration {
    &__button-login {
      width: 180px;
    }
    &__button-next {
      width: 190px;
    }
  }
}

@media (max-width: $break-sm) {
  .registration {
    &__button-login {
      width: 120px;
      margin: auto;
    }
    &__button-next {
      width: 130px;
    }
  }
}
</style>
