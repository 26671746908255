import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AuthService {
  login (user: any) {
    return axios
      .post(API_URL + 'parent/auth/login', {
        email: user.email,
        password: user.password,
        password_confirmation: user.password
      })
      .then(response => {
        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
    return axios
      .delete(API_URL + 'parent/auth/logout', { headers: authHeader() })
  }

  register (user: any) {
    return axios.post(API_URL + 'parent/auth/registration', {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      password: user.password,
      password_confirmation: user.password_confirmation
    })
  }

  passwordRecoveryEmail (data: any) {
    return axios
      .post(API_URL + 'parent/auth/forgot_password', {
        email: data.email
      })
  }

  sendCode (data: any) {
    return axios
      .post(API_URL + 'parent/auth/password_recovery_code', {
        code: data.code
      })
  }

  newPassword (data: any) {
    return axios
      .post(API_URL + 'parent/auth/new_password', {
        code: data.code,
        password: data.password,
        new_password: data.new_password
      })
  }
}

export default new AuthService()
