
import { defineComponent } from 'vue'

import Logo from '@/assets/svg/logo.svg?inline'
import Registration from '@/components/forms/Registration.vue'

export default defineComponent({
  name: 'HomeSection',
  data () {
    return {
    }
  },
  components: {
    Registration,
    Logo
  },
  methods: {
    onSubmit () {
      this.$router.push('/dashboard/home')
    }
  },
  props: {
    msg: String
  }
})
