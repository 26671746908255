<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
#app {
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.home-section__top-section-right .svg {
  display: none;
}
.form-control {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 50px;
  width: 320px;
  height: 40px;
  padding-left: 15px !important;
  color: #828282;

  &:focus {
    outline: 1px solid #7bbbdf !important;
  }
}
.form-group {
  position: relative;
}
.error-feedback {
  position: absolute;
  left: 15px;
  bottom: -7px;
  font-size: 12px;
  color: #ffffff;
  background: #ea242a;
  z-index: 1;
  padding: 5px 10px!important;
  border-radius: 4px;

  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: -8px;
    border: 4px solid transparent;
    border-bottom: 4px solid #ea242a;
  }
}

@media (max-width: $break-sm) {
  .form-control {
    width: 100%;
  }
}
</style>
