<template>
  <div v-if="message">
    <div
      v-for="item in message"
      :key="item.id"
      class="alert alert-danger"
      role="alert"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AlertBlock',
  props: {
    message: {
      type: [String, Boolean],
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.alert {
  position: absolute;
  left: 15px;
  bottom: -7px;
  font-size: 12px;
  color: #ffffff;
  background: #ea242a;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 4px;

  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: -8px;
    border: 4px solid transparent;
    border-bottom: 4px solid #ea242a;
  }
}
</style>
